import React from "react"
import { graphql } from "gatsby"

// import Bio from "../components/bio"
import Layout from "../components/layout"
import Wistia from "../components/wistia"
import SignupForm from "../components/signupForm"
import PlayVideo from "../components/playVideo"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import mainImg from "../images/app/coin-balance.png"
import mainAppImg from "../images/app/main-app-image.png"
import mainImg2 from "../images/app/historical-performance.png"

import SEO from "../components/seo"
// import { rhythm } from "../utils/typography"

class Index extends React.Component {
  onRequestAccessClick = () => {
    this.refs.requestAccess.scrollIntoView({behavior: 'smooth'})
  }

  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle} specialClass="dark-section" logoClass="logo main-page-logo">
        <SEO
          title={this.props.siteTitle || siteTitle}
          description={this.props.siteDescription || data.site.siteMetadata.description}
        />
        <section className="section-home-intro first-slide-effect">
          <div className="container row">
            <div className="col lead-section">
              <h1 className="lead">Privacy is a fundamental human right.</h1>
              <p className="intro">
                That’s why we built Invizi &#8211; an encrypted, free & open source cryptocurrency tracker.
              </p>
              <div className="row" style={{marginBottom: '4rem', justifyContent: 'space-between'}}>
                <div className="col" style={{marginBottom: '3rem'}}>
                  <a className="main-cta button is-large" href="/download" style={{marginBottom: '1rem'}}>Download for Free</a>
                  <p className="small-text"><em>Available on Mac, Window and Linux</em></p>
                </div>
                <div className="col row flex-center" style={{ marginTop: '-1.5rem'}}>
                  <PlayVideo videoId='yvi1tatlbz'/>
                </div>
              </div>
            </div>
            <div className="col home-second" style={{position: 'relative'}}>
              <div className="hero-image">
                <img src={mainImg} className="app-image" alt="Invizi Cryptocurrency Main Dashboard"/>
              </div>
            </div>
          </div>
        </section>
        <section className="second-section">
          <div className="col">
            <h2 className="text-center">Why Invizi ?</h2>
          </div>
          <p className="intro"> </p>
          <div className="container col">
            <div className="feature-table">
              <div className="default-card">
                <div className="default-card--header">
                  <FontAwesomeIcon icon="code" size="2x" className="feature-icon"/>
                  <h3>
                    Open Source and Free
                  </h3>
                </div>
                <div className="default-card--body">
                  <p> Our code is 100% open source
                    and transparent for anyone to audit.
                    Invizi is a public good, 
                    hence is always free to use.
                  </p>
                </div>
              </div>
              <div className="default-card">
                <div className="default-card--header">
                  <FontAwesomeIcon icon="user-lock" size="2x" className="feature-icon"/>
                  <h3>
                    Your data is always private
                  </h3>
                </div>
                <div className="default-card--body">
                  <p>Your financial data is encrypted and
                    stored only on your device. Your data is for you and no one else.
                  </p>
                </div>
              </div>
              <div className="default-card">
                <div className="default-card--header">
                  <FontAwesomeIcon icon="shield-alt" size="2x" className="feature-icon"/>
                  <h3>
                    Made for you, not advertisers
                  </h3>
                </div>
                <div className="default-card--body">
                  <p>
                    Invizi has no ads & no user tracking. You are our esteemed user, not our product.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="row">
          <div className="container col">
            <h2 className="column">Power your productivity without compromising your privacy.</h2>
            <img src={mainAppImg} className="app-image" alt="Historical Balance" style={{'marginTop': '2rem'}}/>
          </div>
        </section>
        <section className="row section-feature-list" ref="requestAccess">
          <div className="col column is-half center">
            <h2 className="text-center">You will love our features</h2>
            <div className="columns feature-list">
              <div className="col">
                <ul style={{'paddingInlineStart': 0}}>
                  <li> <FontAwesomeIcon icon="check" className="feature-list-icon"/>Autosync</li>
                  <li> <FontAwesomeIcon icon="check" className="feature-list-icon"/>Encrypted Journal</li>
                  <li> <FontAwesomeIcon icon="check" className="feature-list-icon"/>Personal Coin Rating</li>
                  <li> <FontAwesomeIcon icon="check" className="feature-list-icon"/>Real time tracking</li>
                </ul>
              </div>
              <div className="col">
                <ul style={{'paddingInlineStart': 0}}>
                  <li> <FontAwesomeIcon icon="check" className="feature-list-icon"/>Smart Calculator</li>
                  <li> <FontAwesomeIcon icon="check" className="feature-list-icon"/>Profit & Loss</li>
                  <li> <FontAwesomeIcon icon="check" className="feature-list-icon"/>Custom Index</li>
                  <li> <FontAwesomeIcon icon="check" className="feature-list-icon"/>Ad Free Coin List</li>
                </ul>
              </div>
            </div>
            <div>
              <a className="button button-light is-large center" href="/features">See More Features</a>
            </div>
          </div>
          <div className="col column" style={{padding: '0 8%'}}>
            <SignupForm/>
          </div>
        </section>
        <section className="textual-section">
          <div className="container col">
            <h2 className="text-center">Stop paying for software with your data</h2>
            <div className="columns col">
              <p className="col" style={{'paddingBottom': '2rem'}}>
                Many companies use data exploitation as their business model. It doesn't have to be that way. We envision a world where our data is our own.
              </p>

            </div>
            <img src={mainImg2} className="app-image" alt="Portfolio Performance" style={{'marginBottom': '5rem'}}/>
          </div>
          <div className="container col" style={{'marginBottom': '5rem'}}>
            <h2 className="text-center">Don’t let your financial information fall into wrong hands.</h2>
            <div className="">
              <p className="col" style={{'paddingBottom': '2rem'}}>
                Cryptocurrency trackers that store your data can be compromised in many ways. They can be hacked, can willingly sell your data to third parties, can indirectly transfer ownership of data through acquisition or can be coerced to reveal information about their customers.
              </p>
              <p>
                With Invizi all your data is local, encrypted and never leaves your computer.
              </p>
            </div>
          </div>
          <div className="container col">
            <h2 className="text-center">Take back your privacy</h2>
            <div className="column text-center">
              <a className="main-cta button is-large" href="/download" style={{marginBottom: '1rem'}}>Download for Free</a>
            </div>
          </div>
        </section>
        <section className="textual-section row">
          <div className="container col columns">
            <div className="column">
              <h2></h2>
            </div>
            <div className="column">
              <p>
              </p>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
