import React from "react"
import addToMailchimp from 'gatsby-plugin-mailchimp';

export default class SignupForm extends React.Component {
  state = {
      email: null,
  }

  _handleChange = (e) => {
      console.log({
          [`${e.target.name}`]: e.target.value,
      });
      this.setState({
          [`${e.target.name}`]: e.target.value,
      });
  }

  _handleSubmit = (e) => {
      e.preventDefault();

      addToMailchimp(this.state.email, this.state)
          .then(({ msg, result }) => {
              console.log('msg', `${result}: ${msg}`);

              if (result !== 'success') {
                  throw msg;
              }
              alert(msg);
          })
          .catch((err) => {
              console.log('err', err);
              alert(err);
          });
  }

  render() {
      return (
        <div>
          <h2>Let's be friends.</h2>
          <form onSubmit={this._handleSubmit}>
            <div className="field">
              <label className="label"></label>
              <div className="control">
                <input
                  className="input is-large"
                  type="email"
                  onChange={this._handleChange}
                  placeholder="Your email"
                  name="email"
                />
              </div>
            </div>
            <input className="main-cta button is-large button-subscribe" type="submit" value="Subscribe"/>
          </form>
        </div>
      );
  }
}
