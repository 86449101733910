import React from "react"

var divStyle = {
  padding: '60.94% 0 0 0',
  position: 'relative'
}

var wrapperStyle = {
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%'
}

class Wistia extends React.Component {
  render() {

    return (
      <React.Fragment>
        <div className="wistia_responsive_padding" style={divStyle}>
          <div className="wistia_responsive_wrapper" style={wrapperStyle}>
            <iframe data-src="https://fast.wistia.net/embed/iframe/4l62rbepeq?videoFoam=true" title="Invizi Dashboard Video" allowtransparency="true" frameBorder="0" scrolling="no" className="wistia_embed" name="wistia_embed" allowFullScreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen width="100%" height="100%"></iframe>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default Wistia
