import React from "react"
import WistiaHelper from '../utils/wistiaHelper'
import '../css/play-video.scss'
import PlayIcon from './playIcon'

export default class PlayVideo extends React.Component {

  constructor (props) {
    super(props)
    this.wistiaClasses = `wistia wistia_async_${props.videoId} popover=true popoverContent=link`
    this.outerClass = ''
    if (props.center === 'true') {
      this.outerClass = 'play-video-outer'
    }
  }

  handleClick = (e) => {
    WistiaHelper.initInlineEmbed()
    WistiaHelper.onVideoReady(this.props.videoId).then(() => {
      document.getElementById('play-wistia').click()
    })
  }

  render() {

    return (
      <div className={this.outerClass}>
        <span className={this.wistiaClasses} style={{display: 'inline', position: 'relative'}}>
          <a id="play-wistia" href="#" onClick={this.handleClick}>
            <PlayIcon/>
          </a>
        </span>
      </div>
    )
  }
}
